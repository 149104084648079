.edit-downline__title {
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: #000000;
  text-transform: capitalize;
}

.edit-downline__subtitle {
  font-size: 18px;
  text-transform: capitalize;
}

.edit-downline__label {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  white-space: nowrap;
}

.edit-downline__wrapper-input {
  margin-bottom: 1em;
}

.edit-downline__input-region {
  margin-bottom: 1em;
}
