.container {
  padding: 24px;
  text-align: center;
  width: 100%;
  flex-direction: column;
}

.main-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.sub-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #4c4c4c;
}

.stock-dropzone {
  cursor: pointer;
  margin: 24px 0;
  border: 2px dashed #808080;
  border-radius: 4px;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropzone-info {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #808080;
  line-height: 19px;
  margin-top: 4px;
}

.dropzone-filename {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #006eff;
  line-height: 19px;
  margin-top: 4px;
}

.button-outline {
  border-radius: 999px !important;
  border: 1px solid #ff7566;
  background-color: white !important;
  color: #ff7566 !important;
  font-weight: 500 !important;
  padding: 10px 16px !important;
}

.button-flat {
  border-radius: 999px !important;
  background-color: white !important;
  color: #ff7566;
  font-weight: 500;
  padding: 10px 16px;
}

.button-fill {
  border-radius: 999px !important;
  color: white;
  background-color: #ff7566 !important;
  font-weight: 500 !important;
  padding: 10px 16px;
}

.button-flat:hover,
.button-outline:hover {
  background-color: white !important;
}

.button-fill:hover {
  background-color: #ff7566 !important;
}

.error-text {
  margin: 12px auto 24px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #ff001f;
}

.warning-box {
  border-radius: 4px;
  border: 1px solid #997000;
  background: #fefad5;
  padding: 12px;
  align-items: center;
}

.warning-text {
  color: #997000;
  margin-left: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: normal;
}

.warning-container {
  text-align: left;
  margin: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px dashed #cccccc;
}

.invalid-list-container {
  max-height: 164px;
  overflow: auto;
}

.warning-title {
  color: #997000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}

.invalid-text-line {
  width: 25%;
  font-weight: bold;
  color: #4c4c4c;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  margin-bottom: 4px;
}

.invalid-text-product {
  width: 70%;
  color: #4c4c4c;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  margin-bottom: 4px;
}

.notes-container {
  text-align: left;
  margin-bottom: 32px;
}

.notes-title {
  margin-bottom: 4px;
  color: #4c4c4c;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.notes-text {
  color: #4c4c4c;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
