.list-downline__container {
  padding: 32px 24px;
}

.list-downline__title {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

.list-downline__content-container {
  background: white;
  border-radius: 8px;
  box-shadow: rgb(229 230 234 / 40%) 0px 4px 8px 4px;
  width: 100%;
  height: max-content;
  margin-top: 32px;
  padding: 20px;
}

.list-downline__content-container .rdt_TableHeader {
  display: none;
}

.list-downline__filter {
  font-size: 18px;
  color: #000000;
  margin-right: 8px;
}

.list-downline__expanded-component-container {
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
  min-height: 48px;
  font-size: 13px;
  align-items: center;
  text-align: left;
}

.list-downline__expanded-component-text {
  color: black;
  flex: 1 0 0px;
  padding: 0 16px;
  align-items: center;
}

.list-downline-expanded-main {
  border-bottom-width: 0px;
  border-top: none;
}

.list-downline-expanded-active {
  background: #ffd6d1;
  border-bottom: 4px solid #ff7566 !important;
  border-bottom-width: 4px;
  border-top: none;
}

.list-downline-expanded-grandchild {
  border-bottom: none;
}

.list-downline__filter-select {
  width: 250px;
}
